.container {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	padding-top: 100px;
	padding-left: 20px;
	padding-right: 20px;
	position: relative;
}

.card {
	width: 600px;
	max-width: 100%;
	height: 300px;
	border-radius: 10px;
	position: relative;
	box-shadow: 3px 3px 10px 4px rgb(0 0 0 / 20%);
	overflow: hidden;
	margin-bottom: 40px;
}

.top {
	background-color: white;
	height: 50%;
	width: 100%;
	padding: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
}

.top h3 {
	font-weight: bold;
	font-style: italic;
	margin-bottom: 5px;
	margin-top: 0;
	font-size: 30px;
	line-height: 28px;
}

.top h4 {
	font-weight: 200;
	font-style: italic;
	margin-bottom: 5px;
	margin-top: 0;
	font-size: 22px;
	line-height: 22px;
}

.bottom {
	background-color: #046492;
	height: 50%;
	width: 100%;
	padding: 20px;
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	flex-direction: column;
	color: white;
}

.bottom h3 {
	font-weight: bold;
	font-size: 18px;
	margin-bottom: 5px;
	margin-top: 0;
	line-height: 22px;
}

.bottom p {
	font-size: 14px;
	margin-bottom: 5px;
	margin-top: 0;
	line-height: 18px;
}

.logo img {
	margin-right: 20px;
	height: 80%;
}

.website {
	font-size: 16px;
	line-height: 22px;
	margin-top: 0;
	margin-bottom: 5px;
}

.social {
	font-size: 16px;
	line-height: 22px;
	margin-top: 0;
	margin-bottom: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.social a {
	margin: 5px;
}

.footer {
	font-size: 16px;
	line-height: 22px;
	margin-top: 0;
	margin-bottom: 5px;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
}

.containerText {
	max-width: 100%;
	width: 600px;
	font-style: italic;
}

.containerText p {
	margin-top: 0;
	margin-bottom: 10px;
}

.containerText a {
	margin-top: 0;
	margin-bottom: 10px;
}

.secondBlock {
	margin-top: 20px;
	margin-bottom: 20px;
}

.hashtag {
	font-weight: bold;
	font-size: 16px;
}

.input {
	height: 40px;
	width: 400px;
	max-width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	box-shadow: 2px 2px 10px 0px rgb(0 0 0 / 20%);
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 10px;
}

.button {
	width: 120px;
	height: 40px;
	border-radius: 10px;
	background-color: #046492;
	border: 1px solid #046492;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 2px 2px 10px 0px rgb(0 0 0 / 20%);
	margin-bottom: 10px;
	color: white;
	text-decoration: none;
}

.buttonDisabled {
	width: 120px;
	height: 40px;
	border-radius: 10px;
	background-color: gray;
	border: 1px solid gray;
	display: flex;
	justify-content: center;
	align-items: center;
}

.error {
	color: red;
	font-size: 12px;
}

.loginTitle {
	font-weight: bold;
	font-size: 18px;
	color: #046492;
}

.modal {
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
}

.cardModal {
	background: white;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
